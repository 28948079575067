// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // dev - new
  // apiUrl: "https://jupiteradmindev.areswear.com/api/",
  // wpUrl: "https://jupiterdev.areswear.com/",
  // designerUrl: "https://imprint.eastus2.cloudapp.azure.com/imprintNextDesigner/",
  // storage: "https://jupiterstorage01.blob.core.windows.net/jupiterblob01/",
  // imageUrl: "https://jupiterstorage01.blob.core.windows.net/assets/Ares-products-image-20240314T113705Z-001/",
  // baseProduct: "14-2",
  // braintree: { tokenizationKey: 'sandbox_bnvdff5g_yq2kp64w4jvhpyz3' },
  // defaultAmount: "13.99",
  // doogmaLicenseKey: "4e25557ac29c30e1fce3cf39c7ca64b3515732fc99ee01ec8222fbfd23782ea1"

  // stage - new
  // apiUrl: "https://jupiterstaging.azurewebsites.net/api/",
  // wpUrl: "https://jupiterwpstaging.eastus2.cloudapp.azure.com/",
  // designerUrl: "https://imprintnextstaging.eastus2.cloudapp.azure.com/designer/",
  // storage: "https://stjupiterstorage02.blob.core.windows.net/jupiterblob01/",
  // imageUrl: "https://stjupiterstorage02.blob.core.windows.net/assets/Ares-products-image-20240314T113705Z-001/",
  // baseProduct: "564-1",
  // braintree: { tokenizationKey: 'sandbox_bnvdff5g_yq2kp64w4jvhpyz3' },
  // defaultAmount: "13.99",
  // doogmaLicenseKey: "4e25557ac29c30e1fce3cf39c7ca64b3515732fc99ee01ec8222fbfd23782ea1"

  // prod - new
  apiUrl: "https://jupiteradmin.areswear.com/api/",
  wpUrl: "https://jupiter.areswear.com/",
  designerUrl: "https://stimprintnext2.eastus2.cloudapp.azure.com/designer/",
  storage: "https://stjupiterstorage02.blob.core.windows.net/jupiterblob01/",
  imageUrl: "https://stjupiterstorage02.blob.core.windows.net/assets/Ares-products-image-20240314T113705Z-001/",
  baseProduct: "564-1",
  braintree: { tokenizationKey: 'production_jyt3gptd_hndc4jr4yxm9ygpt' },
  defaultAmount: "13.99",
  doogmaLicenseKey: "4e25557ac29c30e1fce3cf39c7ca64b3515732fc99ee01ec8222fbfd23782ea1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.