import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { StoreService } from 'src/app/core/services/store.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { ResponseWindowComponent } from '../response-window/response-window.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ProductService } from 'src/app/core/services/product.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/core/services/shared.service';
const bDropIn = require('braintree-web-drop-in')
declare var $: any;

interface Team {
  id: number;
  value: string;
  cartIds: number[];
}
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: [
    './order-details.component.scss', '../../shared/layoutComponentsPreview/layout1/layout1.component.scss'
  ]
})
export class OrderDetailsComponent {
  orderExpanded: boolean = false;
  orderTimingExpanded: boolean = false;
  summaryExpanded: boolean = false;
  $: any;

  rotateArrow: boolean = false;
  rotateTimingArrow: boolean = false;
  rotateOrderSummaryArrow: boolean = false;

  noImage = "../../../../assets/images/no-image.jpg"
  bulkOrderId = ''
  bulkOrer: any;
  key
  selectedIndex = 0
  selectedProdIndex = 0;
  imageLength = 0
  imageIndex = 0;
  approvalStatus: string[] = [];
  rejectArray: any[] = [];

  rejectButtonEnabled: boolean;
  clientToken
  checkoutObj: any;
  taxObject;
  orderCustomFields: any[] = [];
  billingForm: UntypedFormGroup;
  shippingForm: UntypedFormGroup;
  paymentMethod: number = 3;
  isShippingChargeLoading: boolean = true;
  isTaxLoadeding: boolean = true;
  isPaymentCompleted = true;
  rejectionBody: any = {};
  rejectionCode: any = 0;
  rejectionReason: any = "";
  storeRejectionForm: UntypedFormGroup;
  orderRejectionForm: UntypedFormGroup;
  rejectionCodes = [];
  CurrentRejectIndex = -1;
  rejectionCodesForQuote = [];
  rejectionOrderBody: any = {};
  rejectionOrderCode: any = 0;
  rejectionOrderReason: any = "";
  isRejectedCart = true
  colorList = []
  colorObj: any[];
  rotateProdArrow: boolean[] = [];
  authToken: any;
  productForm: FormGroup;
  nanForm: UntypedFormGroup;
  modalIndex = -1
  selectedCustomFields: any[] = []
  showNanButtons = true
  tempSelectedCustomFields: any;
  isAllProductsLoaded: boolean = false;
  totalWeight: any;
  shippingBoxes: any;
  decoPrice: { decoPrice: number; namePrice: number; numberPrice: number; };
  purchaseOrderForm: FormGroup;
  uploadedPO: File[] = [];
  user: any;
  isTaxLoading: boolean = false;
  states: any[] = []
  taxAmount: any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private commonService: CommonService,
    private userService: UserService,
    private dialogue: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private productService: ProductService,
    private sharedServices: SharedService,
  ) {
    if (this.bulkOrer?.cartItemsInfo) {
      this.rotateProdArrow = new Array(this.bulkOrer?.cartItemsInfo.length).fill(false);
    }
    this.productForm = this.formBuilder.group({
      products: this.formBuilder.array([])
    });
    this.nanForm = this.formBuilder.group({
      nans: this.formBuilder.array([])
    });
  }


  toggleOrder() {
    this.orderExpanded = !this.orderExpanded;
  }
  toggleOrderTiming() {
    this.orderTimingExpanded = !this.orderTimingExpanded;
  }
  toggleOrderSummary() {
    this.summaryExpanded = !this.summaryExpanded;
  }
  toggleAccordion(index: number) {
    this.toggleArrow(index);
  }
  toggleArrow(index: number) {
    this.rotateProdArrow[index] = !this.rotateProdArrow[index];
  }

  ngOnInit() {
    this.getStates();
    let checkoutObj = {
      cartItemsInfo: null,
      totalPrice: 0,
      taxAmount: 0,
      totalWeight: this.totalWeight,
      shippingBoxes: this.shippingBoxes,
      customerId: null
    }
    this.checkoutObj = checkoutObj
    this.geColorList();
    this.createStoreRejectionForm();
    this.createOrderRejectionForm()
    this.getStoreRejectionCodes(1)
    this.getStoreRejectionCodesForQuote(2)


    this.createShippingForm()
    this.createBillingForm()
    //this.bulkOrderId = this.route.snapshot.params['refId'] || ''
    this.route.queryParams.subscribe(params => {
      this.key = params['key']; // For example, if your query parameter is named 'id'
      if (this.key) {
        this.isValidKey({ ReferenceId: this.key })
      }

    });
    this.createPurchaseOrderForm()

  }
  async createPurchaseOrderForm() {
    //const poNumber = await this.generatePoNumber();

    this.purchaseOrderForm = this.formBuilder.group({
      organizationId: ['', Validators.required],
      // referenceId: ['', [this.conditionalPoNumberValidator.bind(this)]],
      referenceId: ['', [Validators.required, this.conditionalPoNumberValidator.bind(this)]],
      attachment: [null]
    });
  }
  conditionalPoNumberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();

    if (value) {
      const maxLength = Validators.maxLength(25)(control);
      const minLength = Validators.minLength(3)(control);
      // const alphaNumeric = this.alphaNumericValidator(control);
      const specialChar = this.specialCharValidator(control);

      // Combine all errors
      return {
        ...maxLength,
        ...minLength,
        // ...alphaNumeric
        ...specialChar
      };
    }

    // No errors if the field is empty
    return { required: true };
  }
  specialCharValidator(control: AbstractControl): ValidationErrors | null {
    const valid = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/.test(control.value);  // Accept special characters
    return valid ? null : { specialChar: true };  // Change error key to 'specialChar'
  }
  alphaNumericValidator(control: AbstractControl): ValidationErrors | null {
    const valid = /^[a-zA-Z0-9]*$/.test(control.value);
    return valid ? null : { alphaNumeric: true };
  }

  async generatePoNumber(): Promise<string> {
    let poNumber = '';
    this.commonService.toggleLoading(true);
    try {
      const res: any = await this.sharedServices.getPoNumber().toPromise();
      poNumber = res?.data || '';
    } catch (error) {
      console.error('Error fetching PO number:', error);
    } finally {
      this.commonService.toggleLoading(false);
    }
    return poNumber;
  }

  get organizationId() {
    return this.purchaseOrderForm.get('organizationId');
  }

  get referenceId() {
    return this.purchaseOrderForm.get('referenceId');
  }

  get attachment() {
    return this.purchaseOrderForm.get('attachment');
  }
  uploadCustomArtImg(files: FileList) {
    const file = files.item(0);
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = ['jpg', 'png', 'jpeg', 'gif', 'webp', 'jfif', 'pdf'];

    if (allowedExtensions.includes(fileExtension)) {
      const formData = new FormData();
      formData.append('file', file);

      this.commonService.toggleLoading(true);
      this.storeService.uploadArt(formData).subscribe(
        (response) => {
          this.commonService.toggleLoading(false);
          this.uploadedPO = response?.data;
          this.purchaseOrderForm.patchValue({ attachment: response?.data });
        },
        (err) => {
          this.commonService.toggleLoading(false);
          this.commonService.openErrorSnackBar('File upload failed', '');
        }
      );
    } else {
      this.commonService.openErrorSnackBar('Invalid file format. Please upload an image or PDF.', '');
    }
  }

  geColorList() {
    this.productService.getProductColors().subscribe((res) => {
      this.colorList = res.body.data;
    });
  }
  confirmRejection() {
    if (this.storeRejectionForm.valid) {
      this.rejectionBody = {
        rejectionReason: this.storeRejectionForm.controls['amRejectionText'].value,
        rejectionCode: this.storeRejectionForm.controls['amRejectionCodes'].value.toString()
      }
      this.rejectArray[this.CurrentRejectIndex] = this.rejectionBody
      this.approvalStatus[this.CurrentRejectIndex] = 'rejected';

      let obj = {
        "CartId": this.bulkOrer?.cartItemsInfo[this.CurrentRejectIndex]?.cartId,
        "BulkOrderRejectionReasonCodes": this.rejectArray[this.CurrentRejectIndex]?.rejectionCode,
        "BulkOrderRejectionReason": this.rejectArray[this.CurrentRejectIndex]?.rejectionReason,
        "CartItemStatus": 2
      }
      this.commonService.toggleLoading(true)
      this.storeService.updateIsApproveRejectV1(obj).subscribe(async (response) => {
        if (response?.data) {
          $('#rejectStoreModal').modal('hide');
          this.syncWithOldData(response)
          this.commonService.openSuccessSnackBar(response?.message, "")
          // if (this.bulkOrderId) {  
          //   this.productForm = this.formBuilder.group({
          //     products: this.formBuilder.array([])
          //   });
          //   this.loadBulkOrderDetails(this.bulkOrderId)
          // //   .then(() => {
          // //     this.deleteProductsAfterReload();
          // // })
          // // .catch((error) => {
          // //     console.error('Error loading bulk order details:', error);
          // // });
          // } 
          this.commonService.toggleLoading(false)
        }
      }, error => {
        this.commonService.openErrorSnackBar(error?.error?.message, "")
        this.commonService.toggleLoading(false)

      });
    }
    else {
      this.commonService.openErrorSnackBar('Please fill mandatory fields !', "")

    }
    //this.submit(1);
  }

  getStoreRejectionCodes(type) {
    this.storeService.getRejectionCodesForQuoteAndProduct(type).subscribe(
      (response) => {
        this.rejectionCodes = response.data;
      }
    );
  }
  getStoreRejectionCodesForQuote(type) {
    this.storeService.getRejectionCodesForQuoteAndProduct(type).subscribe(
      (response) => {
        this.rejectionCodesForQuote = response.data;
      }
    );
  }
  createStoreRejectionForm() {
    this.storeRejectionForm = this.formBuilder.group({
      amRejectionCodes: ['', Validators.required],
      amRejectionText: ['', Validators.required]
    });
  }

  createOrderRejectionForm() {
    this.orderRejectionForm = this.formBuilder.group({
      amRejectionCodes: ['', Validators.required],
      amRejectionText: ['', Validators.required]
    });
  }
  getClientToken(customerId) {
    this.userService.getBrainTreeClientTokenForBulkOrder(customerId).subscribe((res: any) => {
      this.clientToken = res.data
      this.createBrainTreeDropInUI()
    }, err => {
      this.createBrainTreeDropInUI()
    })
  }
  createBrainTreeDropInUI() {
    this.disableButtons()
    var _this = this
    bDropIn.create({
      container: '#dropin-container',
      authorization:
        this.clientToken ||
        environment.braintree.tokenizationKey
      ,
      vaultManager: true,
      card: {
        cardholderName: {
          required: true
        },
        // vault: {
        //   allowVaultCardOverride: true
        // },
        overrides: {
          fields: {
            postalCode: null
          }
        },
      },
      paypal: {
        flow: 'vault',
        vault: {
          vaultPayPal: true
        },
      },
      // venmo: {
      //   allowNewBrowserTab: false
      // },
    }, function (createErr, instance) {
      if (createErr) return
      _this.enableButtons()
      var submitButton = $('#payment-request-button')
      submitButton.click(function (event) {
        //instance._model.isGuestCheckout = true 
        instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
          if (requestPaymentMethodErr) {
            console.log(requestPaymentMethodErr);
            _this.braintreeAuthenticationFailed()
            return
          } else {
            let cardInfo = {
              "nameOnCard": payload.details.cardholderName,
              "paymentMethodNonce": payload.nonce
            }
            _this.payByCreditCard(cardInfo)
          }
        });
      });
    })
  }

  braintreeAuthenticationFailed() {
    this.commonService.toggleLoading(false)
    this.enableButtons()
  }
  enableButtons() {
    // $('#back-to-cart-btn').prop('disabled', false)
    $('#pay-now-btn').prop('disabled', false)
  }

  disableButtons() {
    // $('#back-to-cart-btn').prop('disabled', true)
    $('#pay-now-btn').prop('disabled', true)
  }

  payByCreditCard(cardInfo) {

    this.bulkOrer?.cartItemsInfo?.forEach(element => {
      element.cartItemGrids = element.cartItemGrids.filter(item => item.quantity !== 0);
      element.decorationProperties = element.decorationProperties.filter(item => item.art);
      element.personalizationProperties = element.personalizationProperties.filter(item => item.personalizationTypeId);

      if (element.cartItemGrids?.length) {
        element.cartItemGrids.forEach((item, index) => {
          let res = element.nan.find(x => x.sizeId == item.sizeId)
          if (res) {
            item.name = res.name;
            item.number = res.number
            if (res.name || res.number) {
              item.personalize = true
            }
          }
        });
      }

    });

    let checkoutObj = {
      cartItemsInfo: this.bulkOrer?.cartItemsInfo,
      totalPrice: 0,
      taxAmount: 0,
      totalWeight: this.bulkOrer?.totalWeight,
      shippingBoxes: this.bulkOrer?.shippingBoxes,
      customerId: 0
      //cartCount: productsArray?.value?.length,
    }
    this.checkoutObj = checkoutObj
    this.checkoutObj.cartItemsInfo = this.checkoutObj?.cartItemsInfo?.map(element => {
      const { otherProductVariants, productInfo, ...rest } = element;
      return rest;
    });
    // this.bulkOrer?.cartItemsInfo?.forEach(element => { 

    //   element.cartItemGrids = element.cartItemGrids.filter(item => item.quantity !== 0); 
    //   element.decorationProperties = element.decorationProperties.filter(item => item.art);

    //   element.decorationProperties.forEach(element2 => {
    //     element2.colorId= element2?.colorId.trim() !== ""? element2?.colorId.split(',').map(Number) : []; 
    //   });
    // });
    this.bulkOrer?.cartItemsInfo?.forEach(cartItem => {
      cartItem.cartItemGrids = cartItem.cartItemGrids.filter(gridItem => gridItem.quantity !== 0);
      cartItem.decorationProperties = cartItem.decorationProperties.filter(decoration => decoration.art);
      cartItem.decorationProperties.forEach(decoration => {
        if (typeof decoration.colorId === 'string' && decoration.colorId.trim() !== '') {
          decoration.colorId = decoration.colorId.split(',').map(Number);
        } else if (typeof decoration.colorId === 'number') {
          decoration.colorId = [decoration.colorId];
        }
      });
    });


    let formData = new FormData();
    if (this.paymentMethod == 2) {
      this.uploadedPO.forEach((file) => {
        formData.append("file", file);
      });
    }
    this.checkoutObj["billingAddress"] = this.billingForm.value;
    this.checkoutObj["shippingAddress"] = this.shippingForm.value;
    this.checkoutObj["cardInfo"] = cardInfo;
    this.checkoutObj["taxAmount"] = this.bulkOrer?.taxAmount;
    this.checkoutObj["taxSummary"] = this.bulkOrer?.taxSummary;

    // if(this.offerApplied){
    //   this.checkoutObj.cartItemsInfo.forEach(x=>{
    //   if(this.isStrikethrough(x) ){
    //     x.totalPrice=this.getDiscountedPrice(x) 
    //   }
    //   x.cartItemGrids.forEach(y=>{
    //     if(this.isStrikethroughSub(y)){
    //       y.totalPrice=this.isStrikethroughSubForDiscountAmount(y);
    //     }
    //   })
    // })}
    const orderJson = {
      amount: this.bulkOrer?.totalAmount, //this.bulkOrer?.totalPrice
      subTotal: this.bulkOrer?.subTotal,
      shippingAmount: this.bulkOrer?.shippingAmount,
      shippingServiceCodeId: this.bulkOrer?.shippingMethodId,
      paymentMethodNonce: cardInfo?.nonce,
      orderItem: this.checkoutObj,
      paymentType: this.paymentMethod,
      shippingMethodId: this.bulkOrer?.shippingMethodId,
      couponCode: this.bulkOrer?.couponCode,
      CouponDiscountAmount: this.bulkOrer?.totalDiscount,
      OrderCustomFields: this.orderCustomFields,
      orderType: 1,   // 1-bulk order
      referenceId: this.bulkOrer?.referenceId || null,
      OrganizationId: this.purchaseOrderForm?.value?.organizationId,
      PoReferenceId: this.purchaseOrderForm?.value?.referenceId,
    };
    formData.append("orderJson", JSON.stringify(orderJson));
    //this.loading = true;
    this.commonService.toggleLoading(true)
    this.storeService.createOrderForUsers(formData, this.authToken).subscribe(
      (res: any) => {
        this.commonService.toggleLoading(false)
        //this.loading = false;
        // this.scrollToTop()
        let obj = {
          title: "Order Placed",
          text: "Your payment is approved and Order is being processed",
          icon: "success",
          isEmailConfirmed: true,
          isUserClickedOkButton: false
        }
        if (
          res.data.transactionStatus == 6 ||
          res.data.transactionStatus == 8 ||
          res.data.transactionStatus == 13
        ) {

          //window.top.postMessage("cart count-0", "*");
          this.openResponseWindowComponent(obj)
          $('#prodModal').modal('hide');
          //this.router.navigate(['/storemanager/bulkorderentrylist']);

          if (this.paymentMethod == 2) {
            $('#payWithPo').modal('hide');
          }
          if (this.bulkOrderId) {
            this.productForm = this.formBuilder.group({
              products: this.formBuilder.array([])
            });
            this.loadBulkOrderDetails(this.bulkOrderId, true)
            //   .then(() => {
            //     this.deleteProductsAfterReload();
            // })
            // .catch((error) => {
            //     console.error('Error loading bulk order details:', error);
            // });
          }

        }
        else {

          let obj = {
            title: "Order Rejected",
            text: res.data.message,
            icon: "error",
            //isEmailConfirmed:this.user.emailConfirmed, 
          }
          this.openResponseWindowComponent(obj)
        }
      },
      (err) => {
        this.commonService.toggleLoading(false)
        this.enableButtons()
        console.log(err);
        //this.loading = false;

        let obj = {
          title: "Order Rejected",
          text: err.error.data.message,
          icon: "error",
          isEmailConfirmed: true

        }
        this.openResponseWindowComponent(obj)
      }
    );
  }

  payByPo() {
    if (this.purchaseOrderForm?.valid) {
      if (this.purchaseOrderForm?.value?.referenceId?.trim()) {
        this.commonService.toggleLoading(true)
        this.payByCreditCard(null);
        // this.sharedServices.isValidPoNumber(this.purchaseOrderForm?.value?.referenceId).subscribe({
        //   next: (res: any) => {
        //     if (!res.data) {
        //       this.commonService.toggleLoading(false)
        //       this.payByCreditCard(null);
        //     } else {
        //       this.commonService.openErrorSnackBar(res.message, "")
        //       this.commonService.toggleLoading(false)
        //     }
        //   },
        //   error: (err) => {
        //     this.commonService.openErrorSnackBar(err?.error?.message, "")
        //     this.commonService.toggleLoading(false)
        //   }
        // });
      }
      // else {
      //   this.payByCreditCard(null);
      // }
    } else {
      this.purchaseOrderForm.markAllAsTouched();
      this.commonService.toggleLoading(false)
    }
  }

  openResponseWindowComponent(obj) {
    const dialogRef = this.dialogue.open(ResponseWindowComponent, {
      // width: '15%', 
      // width:'20%',
      data: obj,
      disableClose: true,
      position: { top: '20px' }
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      // if (this.user.emailConfirmed)
      //   this.router.navigate(["/enduser/useraccount/orderhistory"]);
      // else
      //   window.top.postMessage("goto home", "*")
    });
  }

  isValidKey(data) {
    this.commonService.toggleLoading(true)
    this.storeService.isValidKey(data).subscribe(async (response) => {
      this.authToken = response?.data?.authToken
      this.commonService.toggleLoading(false)
      this.bulkOrderId = response?.data?.bulkOrderId
      if (response?.data && response?.data?.isVerified && this.bulkOrderId) {
        this.getClientToken(response?.data?.customerId)
        await this.loadBulkOrderDetails(this.bulkOrderId).then(() => {
          //this.getTax(); 
          if (this.showNanButtons) {
            $("#infoModal").modal("show");
          }

        })
          .catch((error) => {
            console.error('Error loading bulk order details:', error);
          });

      }

    }, error => {
      this.commonService.toggleLoading(false)
      this.commonService.openErrorSnackBar(error?.error?.message, "")
    });
  }
  createBillingForm(params?) {
    //creates a form for entering teamstore data.
    this.billingForm = this.formBuilder.group({
      billAttention: new UntypedFormControl(params?.billAttention ? params.billAttention : '', Validators.required),
      address: new UntypedFormControl(params?.billingAddress ? params.billingAddress : '', Validators.required),
      address1: new UntypedFormControl(params?.billingAddress1 ? params.billingAddress1 : ''),
      city: new UntypedFormControl(params?.billingCity ? params.billingCity : '', Validators.required),
      state: new UntypedFormControl(params?.billingState ? params.billingState : '', Validators.required),
      zipCode: new UntypedFormControl(params?.billingZipCode ? params.billingZipCode : '', Validators.required),
    });
  }
  createShippingForm(params?) {
    //creates a form for entering teamstore data.
    this.shippingForm = this.formBuilder.group({
      shipAttention: new UntypedFormControl(params?.shipAttention ? params.shipAttention : '', Validators.required),
      address: new UntypedFormControl(params?.shippingAddress ? params.shippingAddress : '', Validators.required),
      address1: new UntypedFormControl(params?.shippingAddress1 ? params.shippingAddress1 : ''),
      city: new UntypedFormControl(params?.shippingCity ? params.shippingCity : '', Validators.required),
      state: new UntypedFormControl(params?.shippingState ? params.shippingState : '', Validators.required),
      zipCode: new UntypedFormControl(params?.shippingZipCode ? params.shippingZipCode : '', Validators.required),
    });
  }
  async loadBulkOrderDetails(bulkOrerId, wantToShowMsg?) {
    this.commonService.toggleLoading(true)
    return new Promise<void>((resolve, reject) => {
      this.storeService.getbulkOrderDetailsV2(bulkOrerId).subscribe(async (response) => {
        if (response.data && response.data) {
          this.bulkOrer = response.data;
          this.totalWeight = response?.data?.totalWeight
          this.shippingBoxes = response?.data?.shippingBoxes
          this.user = response.data.user;
          this.selectedCustomFields = response?.data?.customFields
          this.tempSelectedCustomFields = JSON.parse(JSON.stringify(this.selectedCustomFields));
          this.isPaymentCompleted = response.data?.cartItemsInfo[0]?.cartType === 1;
          if (this.isPaymentCompleted && !wantToShowMsg) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Order already placed !',
              confirmButtonText: 'OK'
            }).then(() => {

            })
          }
          this.isRejectedCart = response.data?.cartItemStatus === 2;
          if (this.isRejectedCart && !wantToShowMsg) {
            Swal.fire({
              icon: 'success',
              title: 'Rejected',
              text: 'Order already rejected !',
              confirmButtonText: 'OK'
            }).then(() => {

            })
          }
          for (let i = 0; i < response?.data?.cartItemsInfo?.length; i++) {
            try {
              await this.productSelected(response?.data?.cartItemsInfo[i]); // Await the completion of productSelected function
              // let cartItemGrids=response?.data?.cartItemsInfo[i]
              // cartItemGrids?.cartItemGrids.forEach((element,index) => {
              //   this.quantityChanged(i,index,element.quantity)
              // });
              //this.openNANScreen(i)
              let nans = response?.data?.cartItemsInfo[i]?.nan
              const productsArray = this.productForm.get('products') as FormArray;
              const productFormGroup = productsArray.at(i) as FormGroup;
              const nanArray = productFormGroup.get('nan') as FormArray;
              nans?.forEach((element, index) => {
                nanArray.push(this.createNANFormGroup2({ ...element, isNameDisabled: false, isNumberDisabled: false }));
              });
              if (response?.data?.cartItemsInfo?.length - 1 == i) {
                this.isAllProductsLoaded = true
                this.commonService.toggleLoading(false)
              }
              await this.calculateTotalPrice(i);
              resolve();
            } catch (error) {
              console.error('Error in productSelected:', error);
            }
          }
          //this.getCurrentProductUsedColors()
          this.createBillingForm(response?.data?.billingAddress);
          this.createShippingForm(response?.data?.shippingAddress);
          this.getSelectedProdImage()
          this.setProdStatus()
          this.commonService.toggleLoading(false)

          if (this.isRejectedCart || this.isPaymentCompleted) {
            this.showNanButtons = false
          }
          this.rotateOrderSummaryArrow = true;
          this.toggleOrderSummary()
        } else {
          this.commonService.toggleLoading(false);
          reject(new Error('No data received'));
        }
      }, error => {
        this.commonService.toggleLoading(false)
        reject(error);
      });
    });
  }
  createNANFormGroup2(item?) {
    return this.formBuilder.group({
      id: item?.id || 0,
      cartId: item?.cartId,
      teams: this.formBuilder.array([]),
      players: this.formBuilder.array([]),
      team: item?.team || '',
      teamId: item?.teamId || null,
      teamIds: item?.teamIds || null,
      player: item?.player || '',
      playerId: item?.playerId || 0,
      productId: item?.productId || 0,
      productName: { value: item?.productName || '', disabled: true },
      color: { value: item?.color || '', disabled: true },
      colorId: item?.colorId || 0,
      size: item?.size || '',
      sizeId: item?.sizeId || 0,
      name: item?.name || '',
      number: item?.number || '',
      isNameDisabled: item?.isNameDisabled || false,
      isNumberDisabled: item?.isNumberDisabled || false,
      sortOrder: item?.sortOrder
    });
  }
  isValidNANButton(item, prodIndex) {
    let status = false
    if (item.value.personalizationProperties?.length) {
      if (this.isValidPersonalizedOptionsInProuct(prodIndex)) {
        status = true
      }
      if (this.bulkOrer.customFields?.length) {
        status = true
      }
    }
    return status
  }
  isValidPersonalizedOptionsInProuct(productIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    const garmentValidationResults: boolean[] = [];
    const garmentsRequestedArray = (productsArray.at(productIndex).get('personalizationProperties') as FormArray);

    garmentsRequestedArray.controls.forEach((garmentRequestedControl) => {
      if (garmentRequestedControl.value.personalizationTypeId) {
        const isValid = garmentRequestedControl.valid;
        garmentValidationResults.push(isValid);
      } else {
        garmentValidationResults.push(false);
      }
    });
    const allGarmentsValid = garmentValidationResults.every((isValid) => isValid);
    return allGarmentsValid;
  }
  openNANsScreen(i: any) {
    this.modalIndex = i;
    const productsArray = this.productForm.get('products') as FormArray;
    const productGroup = productsArray.at(i) as FormGroup;
    const totalQuantity = productGroup.get('totalQuantity').value;
    const nansArray = this.nanForm.get('nans') as FormArray;
    nansArray.clear();

    // const nanArray = (productGroup.get('nan') as FormArray);
    // nanArray.clear();

    const mappedData = productGroup?.value?.personalizationProperties?.map(item => ({
      showName: Number(item.personalizationTypeId) === 1,
      showNumber: Number(item.personalizationTypeId) === 2
    }));

    const hasShowName = mappedData.some(item => item.showName);
    const hasShowNumber = mappedData.some(item => item.showNumber);

    if (!this.selectedCustomFields.length && totalQuantity === 0) {
      this.commonService.openErrorSnackBar('Please fill quantity', "");
    } else if (!this.selectedCustomFields.length && !this.isValidPersonalizedOptionsInProuctForNAN(i)) {
      this.commonService.openErrorSnackBar('Please fill personalization settings !', "");
    } else {
      const filteredCartItemGrids = productGroup?.value?.cartItemGrids.filter(item => Number(item.quantity) !== 0);

      if (filteredCartItemGrids) {
        if (this.bulkOrer.cartItemsInfo[this.modalIndex]?.nan?.length) {
          this.bulkOrer.cartItemsInfo[this.modalIndex]?.nan?.forEach(element => {
            //if (filteredCartItemGrids.some(obj => Number(obj.sizeId) === Number(element.sizeId))) {
            const obj = {
              id: element?.id || 0,
              cartId: element?.cartId,
              team: '',
              teamId: element?.teamId || null,
              teamIds: element?.teamIds || null,
              teams: element?.teamIds?.split(','),
              players: element?.player?.split(','),
              player: element?.player || '',
              playerId: '' || 0,
              productName: productGroup.value.productDisplayName,
              productId: productGroup.value.productId,
              color: productGroup.value.selectedColor.name,
              colorId: productGroup.value.selectedColor.id,
              size: element?.size ? element.size : '',
              sizeId: element?.sizeId || '',
              name: element.name || '',
              number: element.number || '',
              isNameDisabled: hasShowName,
              isNumberDisabled: hasShowNumber,
              sortOrder: element?.sortOrder
            };
            //nanArray.push(this.createNANFormGroup(obj));
            nansArray.push(this.createNANFormGroup(obj));
            //}
          });
        } else {
          filteredCartItemGrids?.forEach((element, index) => {
            if (index == 0) {
              const obj = {
                id: element?.id || 0,
                cartId: element?.shoppingCartId,
                team: '',
                teamId: element?.teamId || null,
                teamIds: element?.teamIds || null,
                teams: element?.teamId?.split(','),
                players: element?.player?.split(','),
                player: element?.player || '',
                playerId: '' || 0,
                productName: productGroup.value.productDisplayName,
                productId: productGroup.value.productId,
                color: productGroup.value.selectedColor.name,
                colorId: productGroup.value.selectedColor.id,
                size: element?.size ? element.size : '',
                sizeId: element?.sizeId || '',
                name: element.name || '',
                number: element.number || '',
                isNameDisabled: hasShowName,
                isNumberDisabled: hasShowNumber,
                sortOrder: element?.sortOrder
              };
              // nanArray.push(this.createNANFormGroup(obj));
              nansArray.push(this.createNANFormGroup(obj));
            }

          });
        }
        // if(this.isOrderDetailPage){
        //   this.nanForm.disable()
        // }
        $('#nanModal').modal('toggle');
      }
    }
  }
  createNANFormGroup(item?) {
    let teams = this.formBuilder.array([]);
    let players = this.formBuilder.array([]);

    if (item?.teams && this.getTeams()?.length > 0) {
      item.teams.forEach(team => {
        let number = Number(team)
        teams.push(this.formBuilder.group({ teamId: [number ? number : ''] }));
      });
      if (item?.teams?.length < this.getTeams()?.length) {
        let balance = this.getTeams()?.length - item?.teams?.length
        for (let i = 0; i < balance; i++) {
          teams.push(this.formBuilder.group({ teamId: [''] }));
        }
      }
    }

    if (!item?.teams && this.getTeams()?.length > 0) {
      let teamList = this.getTeams()
      teamList.forEach(team => {
        teams.push(this.formBuilder.group({ teamId: [''] }));
      });
    }

    if (item?.players && this.getPlayerValidations()?.length > 0) {
      item.players?.forEach(player => {
        players.push(this.formBuilder.group({ player: [player] }));
      });

      if (item?.players?.length < this.getPlayerValidations()?.length) {
        let balance = this.getPlayerValidations()?.length - item?.players?.length
        for (let i = 0; i < balance; i++) {
          players.push(this.formBuilder.group({ player: [''] }));
        }
      }

    }

    if (!item?.players && this.getPlayerValidations()?.length > 0) {
      let playersList = this.getPlayerValidations()
      playersList?.forEach(player => {
        players.push(this.formBuilder.group({ player: [''] }));
      });
    }
    return this.formBuilder.group({
      id: item?.id || 0,
      cartId: item?.cartId,
      teams: teams,
      players: players,
      team: item?.team || '',
      teamId: item?.teamId || null,
      teamIds: item?.teamIds || null,
      player: item?.player || '',
      playerId: item?.playerId || 0,
      productId: item?.productId || 0,
      productName: { value: item?.productName || '', disabled: true },
      color: { value: item?.color || '', disabled: true },
      colorId: item?.colorId || 0,
      size: item?.size || '',
      sizeId: item?.sizeId || 0,
      name: { value: item?.name || '', disabled: !item.isNameDisabled ? true : false },
      number: { value: item?.number || '', disabled: !item.isNumberDisabled ? true : false },
      isNameDisabled: item?.isNameDisabled || false,
      isNumberDisabled: item?.isNumberDisabled || false,
      sortOrder: item?.sortOrder
    });
  }
  isValidPersonalizedOptionsInProuctForNAN(productIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    const garmentValidationResults: boolean[] = [];
    const garmentsRequestedArray = (productsArray.at(productIndex).get('personalizationProperties') as FormArray);

    garmentsRequestedArray.controls.forEach((garmentRequestedControl, index) => {
      let isValid = false
      if (this.selectedCustomFields.length) {
        if (index == 0) {
          if (garmentRequestedControl.value.personalizationTypeId == null) {
            isValid = true
          } else {
            isValid = garmentRequestedControl.valid;
          }
        } else {
          isValid = garmentRequestedControl.valid;
        }
      } else {
        isValid = garmentRequestedControl.valid;
      }
      garmentValidationResults.push(isValid);
    });
    const allGarmentsValid = garmentValidationResults.every((isValid) => isValid);
    return allGarmentsValid;
  }
  productSelected(product): Promise<any> {
    return new Promise((resolve, reject) => {
      //document.getElementById("addProductModal2").classList.add("loading-cursor");
      this.commonService.toggleLoading(true);
      const productId = product?.colorId ? `${product.id || product.productId}-${product.colorId}` : product.id || product.productId;
      // this.storeService.getDirectProductDetail(productId, 2).subscribe(
      //   (response) => {
      let obj = {};
      if (product.productColorInfo) {
        obj = {
          hexValue: product.productColorInfo.hexCode,
          id: product.productColorInfo.id,
          name: product.productColorInfo.name,
          images: [{ thumbnail: product.productImages[0] }],
          swatch: product.productColorInfo?.swatch
        };
      }
      let newItem = { ...product };
      this.addProduct(newItem);

      this.commonService.toggleLoading(false);
      resolve(product); // Resolve the promise with the product when the function completes
      //},
      // (error) => {
      //   reject(error); // Reject the promise if there's an error
      // }
      //);
    });
  }
  addProduct(newItem) {
    const productFormGroup = this.formBuilder.group({
      cartId: newItem.cartId || 0,
      productId: newItem.productId || newItem.id,
      productDisplayName: newItem.productDisplayName || newItem.displayName,
      selectedColor: newItem.selectedColor || newItem.productColorInfo,
      colorId: newItem.productColorInfo.id || newItem.selectedColor.id,
      color: newItem?.color,
      cartItemGrids: this.formBuilder.array([]),
      decorationProperties: this.formBuilder.array([this.createDecorationPropertyGroup()]),
      personalizationProperties: this.formBuilder.array([this.createPersonalizationForm(null)]),
      perUnit: this.getProductPerUnitPrice(newItem),
      discount: newItem?.discount || 0,
      totalPrice: newItem?.totalPrice ? newItem.totalPrice : '',
      namePersonalizationQuantity: 0,
      numberPersonalizationQuantity: 0,
      totalQuantity: newItem.totalQuantity || 0,
      quantity: newItem.totalQuantity || 0,
      productInfo: newItem?.productInfo ? newItem?.productInfo : '',
      nan: this.formBuilder.array([]),
      discountStatus: newItem?.discountStatus || 0,
      isDiscountApproved: newItem?.isDiscountApproved || false,
      decoPrice: newItem?.decoPrice || 0,
      personalizationPrice: Number(newItem.personalizationPrice || 0),
      purchaseType: newItem?.purchaseType || 1,
      discountApprovedAmount: newItem?.discountApproved || 0,
      discountType: newItem?.discountType || 0,
      oldTotalPrice: newItem?.oldTotalPrice || 0,
      isCustomerProvideGoods: newItem?.isCustomerProvideGoods ? newItem.isCustomerProvideGoods : false
    });
    const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
    const personalizationPropertiesList = productFormGroup.get('personalizationProperties') as FormArray;

    const decorationPropertiesList = productFormGroup.get('decorationProperties') as FormArray;

    if (this.bulkOrderId && newItem?.cartItemGrids) {

      let sizeVariants = newItem?.cartItemGrids;
      sizeVariants.forEach(sizeVariant => {
        sizesArray.push(this.createSizeVariantFormGroup(sizeVariant));
      });


      let personalizationProperties = newItem?.personalizationProperties;
      personalizationProperties.forEach(pers => {
        personalizationPropertiesList.push(this.createPersonalizationForm(pers));
      });
      if (personalizationPropertiesList?.length > 0 && personalizationProperties?.length > 0) {
        personalizationPropertiesList.removeAt(0);
      }


      let decoProperties = newItem?.decorationProperties;
      decoProperties.forEach(dec => {
        const colorId: number[] = dec.colorId.split(',').map(Number);
        dec.colorId = colorId
        decorationPropertiesList.push(this.createDecorationPropertyGroup(dec, newItem));
      });
      if (decorationPropertiesList?.length > 0 && decoProperties?.length > 0) {
        decorationPropertiesList.removeAt(0);
      }


    }
    else {
      let sizeVariants = newItem?.selectedColor?.sizeVariants;
      sizeVariants.forEach(sizeVariant => {
        sizeVariant.sellingPrice = (newItem?.isCustomerProvideGoods == true ? 0 : sizeVariant?.sellingPrice)
        sizesArray.push(this.createSizeVariantFormGroup(sizeVariant));
      });
    }


    (this.productForm.get('products') as FormArray).push(productFormGroup);
  }
  getProductPerUnitPrice(newItem) {
    let price = 0
    if (newItem?.cartItemGrids) {
      price = (newItem?.isCustomerProvideGoods == true ? 0 : newItem?.cartItemGrids[0]?.sellingPrice)
    }
    if (newItem?.selectedColor?.sizeVariants) {
      price = (newItem?.isCustomerProvideGoods == true ? 0 : newItem?.selectedColor?.sizeVariants[0]?.sellingPrice)
    }
    return price
  }
  createPersonalizationForm(pers?) {
    return this.formBuilder.group({
      shoppingCartId: [pers?.shoppingCartId || 0],
      personalizationTypeId: [pers?.personalizationTypeId || null, Validators.required],
      positionId: [pers?.positionId || "", Validators.required],
      serviceId: [pers?.serviceId || "", Validators.required],
      colorId: [pers?.colorId || "", Validators.required],
      sizeId: [pers?.sizeId || "", Validators.required],
      fontId: [pers?.fontId || "", Validators.required],
      personalizationCost: pers?.personalizationCost || 0,
    });
  }
  createSizeVariantFormGroup(sizeVariant) {
    return this.formBuilder.group({
      shoppingCartId: sizeVariant.shoppingCartId || 0,
      sizeId: sizeVariant.sizeId,
      size: sizeVariant.sizeName || sizeVariant.size,
      originalPrice: sizeVariant.originalPrice || sizeVariant.price,
      sellingPrice: sizeVariant.sellingPrice,
      storePrice: sizeVariant.sellingPrice,  //sizeVariant.storePrice
      sortOrder: sizeVariant.sortOrder || null,
      quantity: sizeVariant.quantity || 0,
      decoPrice: sizeVariant.decoPrice || 0,
      name: sizeVariant.name || null,
      number: sizeVariant.number || null,
      personalizationNamePrice: sizeVariant.personalizationNamePrice || 0,
      personalizationNumberPrice: sizeVariant.personalizationNumberPrice || 0,
      totalPrice: sizeVariant.totalPrice || 0
    });
  }
  createDecorationPropertyGroup(obj?, productDetails?) {
    return this.formBuilder.group({
      shoppingCartId: obj?.shoppingCartId,
      art: obj?.art,
      id: obj?.id,
      //positionId: this.formBuilder.control(obj.positionId || '', Validators.required),
      decoGroupMethodId: this.formBuilder.control(productDetails?.productInfo?.decoGroupMethodId || '', Validators.required),
      serviceName: productDetails?.productInfo?.decoGroupMethod,
      //sizeId: this.formBuilder.control(obj.sizeId || ''),
      serviceId: this.formBuilder.control(productDetails?.productInfo?.decoGroupMethodId || ''),
      colorId: this.formBuilder.control(obj?.colorId || [], Validators.required),
      colorCount: this.formBuilder.control(obj?.colorCount || 0, Validators.required),
      decoPrice: obj?.decoPrice || 0
    })
  }
  setProdStatus() {
    this.bulkOrer?.cartItemsInfo.forEach((element, index) => {
      if (element?.cartItemStatus === 1) {
        this.approvalStatus[index] = 'approved';
        this.rejectArray[index] = {};
      } else if (element?.cartItemStatus === 2) {
        this.approvalStatus[index] = 'rejected';
        this.rejectArray[index] = {};
      } else if (element?.cartItemStatus === 0) {
        this.approvalStatus[index] = 'noAction';
        this.rejectArray[index] = {};
      }
    });
  }
  getFilteredProducts(prod) {
    const filtered = prod?.filter(obj => Number(obj.quantity) > 0);
    return filtered
  }
  getTypeName(item) {
    let type = ''
    if (item?.decorationProperties[0]?.length && item?.decorationProperties[0]?.art) {
      if (item?.decorationProperties[0]?.serviceId == 1) {
        type = 'Print'
      } else if (item?.decorationProperties[0]?.serviceId == 2) {
        type = 'Embroidery'
      }
    } else {
      type = 'Blank'
    }
    return type;
  }

  getSelectedProdImage(): string {
    let selectedImage = 'noImage.jpg'; // Default image if no image is found
    const images = this.bulkOrer?.cartItemsInfo?.[this.selectedProdIndex]?.productImages;
    this.imageLength = images?.length
    if (images && images.length > 0 && this.selectedIndex > -1) {
      if (this.selectedIndex < images.length) {
        selectedImage = images[this.selectedIndex];
        this.imageIndex = this.selectedIndex + 1
      } else {
        selectedImage = 'noImage.jpg'
        this.selectedIndex = this.selectedIndex - 1
      }
    }
    if (this.selectedIndex == -1) {
      this.selectedIndex = 0
    }
    return selectedImage;
  }
  getSelectedProdImage2(): string {

    let selectedImage = 'noImage.jpg';
    const images = this.bulkOrer?.cartItemsInfo?.[this.selectedProdIndex]?.productImages;
    this.imageLength = images?.length
    if (images && images.length > 0 && this.selectedIndex > -1) {
      if (this.selectedIndex < images.length) {
        if (images[this.selectedIndex + 1]) {
          selectedImage = images[this.selectedIndex + 1];
        }
        else {
          selectedImage = images[0];
        }
      } else {
        selectedImage = 'noImage.jpg'
      }
    }
    return selectedImage;
  }

  NextClicked() {
    this.selectedIndex = this.selectedIndex + 1
    this.getSelectedProdImage()
  }
  PreviousClicked() {
    this.selectedIndex = this.selectedIndex - 1
    this.getSelectedProdImage()
  }

  getCurrentProductUsedColors() {
    this.colorObj = [] = []

    this.bulkOrer?.cartItemsInfo[this.selectedProdIndex]?.decorationProperties.forEach((deco) => {
      if (deco?.colorCount > 0) {
        this.colorObj.push(...deco.colorInfo)
      }
    });
    this.bulkOrer?.cartItemsInfo[this.selectedProdIndex]?.personalizationProperties.forEach((deco) => {
      if (deco?.personalizationTypeId) {
        let obj = {
          hexCode: deco?.hexCode,
          id: deco?.colorId,
          name: deco?.colorName
        }
        this.colorObj.push(obj)
      }

    });
    return this.colorObj
  }

  getCurrentProductUsedFonts() {
    let fontObj = []
    this.bulkOrer?.cartItemsInfo[this.selectedProdIndex]?.personalizationProperties.forEach((deco) => {
      fontObj.push(deco?.fontName)
    });
    return fontObj
  }

  approveClicked(index: number) {
    this.CurrentRejectIndex = index
    let status = ''
    if (this.approvalStatus[index] == 'approved') {
      status = 'noAction'
    } else {
      status = 'approved'
    }
    this.updateApprovalStatus(index, status);
  }
  deleteProductsAfterReload() {
    const productsArray = this.productForm.get('products') as FormArray;
    const numberOfEntriesToRemove = this.bulkOrer?.cartItemsInfo?.length
    const length = productsArray.length;
    for (let i = 0; i < numberOfEntriesToRemove; i++) {
      productsArray.removeAt(length - 1 - i);
    }
  }
  confirmApprove(status: number) {
    this.rejectionBody = {
      rejectionReason: this.storeRejectionForm.controls['amRejectionText'].value,
      rejectionCode: this.storeRejectionForm.controls['amRejectionCodes'].value.toString()
    };
    this.rejectArray[this.CurrentRejectIndex] = this.rejectionBody;
    this.commonService.toggleLoading(true);
    const obj = {
      "CartId": this.bulkOrer?.cartItemsInfo[this.CurrentRejectIndex]?.cartId,
      "BulkOrderRejectionReasonCodes": "",
      "BulkOrderRejectionReason": "",
      "CartItemStatus": status
    };
    this.storeService.updateIsApproveRejectV1(obj).subscribe(async (response) => {
      if (response?.data) {
        this.syncWithOldData(response)
        this.commonService.openSuccessSnackBar(response?.message, "");
        // if (this.bulkOrderId) {  
        //   this.productForm = this.formBuilder.group({
        //     products: this.formBuilder.array([])
        //   });
        //   this.loadBulkOrderDetails(this.bulkOrderId)
        // //   .then(() => {
        // //     this.deleteProductsAfterReload();
        // // })
        // // .catch((error) => {
        // //     console.error('Error loading bulk order details:', error);
        // // });
        // } 
      }
      this.commonService.toggleLoading(false);
    }, error => {
      this.commonService.openErrorSnackBar(error?.error?.message, "");
      this.commonService.toggleLoading(false);
    });
  }


  rejectClicked(index: number) {
    this.CurrentRejectIndex = index
    if (this.approvalStatus[index] === 'rejected') {
      this.updateApprovalStatus(index, 'noAction');
    } else {
      this.openRejectionModal();
    }
  }
  updateApprovalStatus(index: number, newStatus: string) {
    const status: number = (newStatus === 'approved') ? 1 : 0;
    this.CurrentRejectIndex = index;
    this.approvalStatus[index] = newStatus;
    this.confirmApprove(status);
  }

  isAnyOneRejected(): boolean {
    if (!this.approvalStatus?.length) {
      return true;
    }
    this.rejectButtonEnabled = this.approvalStatus.includes('rejected');
    this.rejectButtonEnabled = this.approvalStatus.includes('noAction');

    return this.rejectButtonEnabled;
  }
  isAllApproved() {
    if (this.approvalStatus?.length > 0) {
      this.rejectButtonEnabled = this.approvalStatus.some(status => status === 'approved');
      return !this.rejectButtonEnabled
    } else {
      return true;
    }
  }
  rejectOrderClicked() {
    let isPending = this.approvalStatus.includes('noAction');
    if (!isPending) {
      this.openOrderRejectionModal()
    } else {
      this.commonService.openErrorSnackBar('Approve / Reject each product to reject the order !', "")
    }
  }
  rejectOrders() {

    if (this.orderRejectionForm.valid) {
      // let isPending = this.approvalStatus.includes('noAction');
      // if (!isPending) {

      //let isAnyRejected = this.approvalStatus.includes('rejected');
      //if (isAnyRejected) {
      let items = []
      // this.approvalStatus?.forEach((x, index) => {
      //   let obj = {}
      //   if (x == 'rejected') {
      //     obj = {
      //       "CartId": this.bulkOrer?.cartItemsInfo[index]?.cartId,
      //       "BulkOrderRejectionReasonCodes": this.rejectArray[index]?.rejectionCode,
      //       "BulkOrderRejectionReason": this.rejectArray[index]?.rejectionReason,
      //       "CartItemStatus": 2
      //     }
      //     items.push(obj)
      //   }
      //   // else if (x == 'approved') {
      //   //   obj = {
      //   //     "CartId": this.bulkOrer?.cartItemsInfo[index]?.cartId,
      //   //     "BulkOrderRejectionReasonCodes": "",
      //   //     "BulkOrderRejectionReason": "",
      //   //     "CartItemStatus": 1
      //   //   }
      //   // }else if(x=='noAction'){
      //   //   obj = {
      //   //     "CartId": this.bulkOrer?.cartItemsInfo[index]?.cartId,
      //   //     "BulkOrderRejectionReasonCodes": "",
      //   //     "BulkOrderRejectionReason": "",
      //   //     "CartItemStatus": 0
      //   //   }
      //   // }


      // })
      let mainObj = {
        "BulkOrderShoppingCartMasterId": this.bulkOrer?.bulkOrderShoppingCartMasterId,
        "BulkOrderRejectionReasonCodes": this.orderRejectionForm.value.amRejectionCodes.toString(),
        "BulkOrderRejectionReason": this.orderRejectionForm.value.amRejectionText,
        "CartItemStatus": 2,
        "Items": items
      }
      this.commonService.toggleLoading(true)
      this.storeService.updateOrderStatusToReject(mainObj).subscribe(async (response) => {

        if (response?.data) {
          $('#rejectOrderModal').modal('hide');
          this.commonService.openSuccessSnackBar(response?.message, "")
          if (this.bulkOrderId) {
            this.productForm = this.formBuilder.group({
              products: this.formBuilder.array([])
            });
            this.loadBulkOrderDetails(this.bulkOrderId, true)
            //   .then(() => {
            //     this.deleteProductsAfterReload();
            // })
            // .catch((error) => {
            //     console.error('Error loading bulk order details:', error);
            // });
          }
        }

      }, error => {
        this.commonService.openErrorSnackBar(error?.error?.message, "")
      });

    }

    // }
    // else {
    //   this.commonService.openErrorSnackBar('Please complete approval process !', "")

    // }
    //}else{
    //this.commonService.openErrorSnackBar('Please fill mandatory fields !', "")
    //}

  }
  approveAndPay(mainObj) {
    this.storeService.updateIsApproveRejectV2(mainObj).subscribe(async (response) => {

      if (response?.data) {
        this.commonService.openSuccessSnackBar(response?.message, "")
        //this.loadBulkOrderDetails(this.bulkOrderId)
        this.commonService.toggleLoading(false)
        // this.openCheckoutScreen()
        $('#paymentTypes').modal('show');
      }

    }, error => {
      this.commonService.openErrorSnackBar(error?.error?.message, "")
      this.commonService.toggleLoading(false)
    });
  }
  payWithPo() {
    $('#paymentTypes').modal('hide');
    this.purchaseOrderForm.patchValue({ organizationId: this.bulkOrer?.organizationId })
    this.purchaseOrderForm.get('organizationId')?.disable();
    $('#payWithPo').modal('show');
  }

  payment(mainObj) {
    this.storeService.updateIsApproveRejectV1(mainObj).subscribe(async (response) => {

      if (response?.data) {
        this.commonService.openSuccessSnackBar(response?.message, "")
        //this.loadBulkOrderDetails(this.bulkOrderId)
        this.commonService.toggleLoading(false)
        this.openCheckoutScreen()
      }

    }, error => {
      this.commonService.openErrorSnackBar(error?.error?.message, "")
      this.commonService.toggleLoading(false)
    });
  }

  approveOrders() {
    let isPending = this.approvalStatus.includes('noAction');
    if (!isPending) {

      let isAnyRejected = this.approvalStatus.includes('rejected');
      if (!isAnyRejected) {

        let items = []
        this.approvalStatus?.forEach((x, index) => {
          let obj = {}
          if (x == 'approved') {
            obj = {
              "CartId": this.bulkOrer?.cartItemsInfo[index]?.cartId,
              "BulkOrderRejectionReasonCodes": "",
              "BulkOrderRejectionReason": "",
              "CartItemStatus": 1
            }
          }
          items.push(obj)

        })
        let mainObj = {
          "BulkOrderShoppingCartMasterId": this.bulkOrer?.bulkOrderShoppingCartMasterId,
          "BulkOrderRejectionReasonCodes": null,
          "BulkOrderRejectionReason": null,
          "CartItemStatus": 1,
          "Items": items,
          "CartId": items[0]?.CartId
        }
        const productsArray = this.productForm.get('products') as FormArray;
        let isAnyPending = productsArray.value.some(x => x.discountStatus == 1)

        if (isAnyPending) {
          Swal.fire({
            title: "Discount approval pending",
            html: `<h5>There is a discount approval pending for your quote. Are you sure you would like to proceed with check out?</h5>`,
            icon: "info",
            confirmButtonText: "Continue",
            cancelButtonText: "Cancel",
            showCancelButton: true,
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.commonService.toggleLoading(true)
              // this.payment(mainObj)
              this.approveAndPay(mainObj)
            }
          });
        } else {
          this.commonService.toggleLoading(true)
          // this.payment(mainObj)
          this.approveAndPay(mainObj)
        }

      } else {
        this.commonService.openErrorSnackBar('Approve all products to place an order !', "")
      }
    }
    else {
      this.commonService.openErrorSnackBar('Approve / Reject each product to reject the order !', "")

    }

  }


  openCheckoutScreen() {
    $('#paymentTypes').modal('hide');
    $('#prodModal').modal('toggle');
  }
  openRejectionModal() {
    this.createStoreRejectionForm();
    $('#rejectStoreModal').modal('toggle');
  }
  openOrderRejectionModal() {
    this.createOrderRejectionForm();
    $('#rejectOrderModal').modal('toggle');
  }
  checkout() {
    switch (this.paymentMethod) {
      case 3:
        this.commonService.toggleLoading(true)
        $('#payment-request-button').click();
        this.disableButtons()
        // $('#cardForm').submit()
        break;
      case 1:
        //this.payByInvoice();
        break;
      case 2:
        // this.payByPo();
        this.payByCreditCard(null);
        break;
      default:
        this.commonService.openWarningSnackBar(
          "Select valid payment method",
          ""
        );
        break;
    }
  }

  isCheckoutDisabled() {
    return this.isShippingChargeLoading || this.isTaxLoadeding;
  }
  cancelRejection() {
    this.rejectionBody = {};
    this.rejectionCode = 0;
    this.rejectionReason = "";
    $('#rejectStoreModal').modal('hide');
  }
  cancelOrderRejection() {
    this.rejectionOrderBody = {};
    this.rejectionOrderCode = 0;
    this.rejectionOrderReason = "";
    $('#rejectOrderModal').modal('hide');
  }
  getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`;
  }
  getDate() {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(this.bulkOrer?.expectedDate, 'EEEE, MMMM d, y');
  }
  getDay() {
    var datePipe = new DatePipe('en-US');
    return datePipe.transform(this.bulkOrer?.expectedDate, 'EEEE');
  }
  closePopup() {
    $('.modal').modal('hide')
  }
  getPriceBySizeName(sizeId, product) {
    if (sizeId && product) {
      let sizeObj = product.productInfo.variants[0].sizeVariants.find(
        (size) => size.sizeId == sizeId
      );
      return ((product?.isCustomerProvideGoods == true ? 0 : sizeObj ? sizeObj.sellingPrice : 0))
    } else {
      return 0;
    }
  }
  getUpChargePriceBySizeName(sizeId, product) {
    if (!sizeId || !product?.cartItemGrids?.length) {
      return 0;
    }

    const singleSizeById = product.cartItemGrids.find(x => x.sizeId === sizeId);

    const sellingPrice = singleSizeById ? singleSizeById.sellingPrice : 0;
    const basePrice = product?.cartItemGrids[0]?.sellingPrice || 0;

    return product?.isCustomerProvideGoods ? 0 : (sellingPrice - basePrice);
  }

  getTotalQuantity(productIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(productIndex) as FormGroup;
    const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
    const nanArray = productFormGroup.get('nan') as FormArray;

    let totalQuantity = 0
    for (let i = 0; i < sizesArray.value.length; i++) {
      let totalSizeQuantityInNAN = nanArray.value.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
      let nanSizeQuantity = totalSizeQuantityInNAN?.length

      if (sizesArray.value[i].quantity > nanSizeQuantity) {
        totalQuantity += sizesArray.value[i].quantity
      } else {
        totalQuantity += nanSizeQuantity
      }
    }
    return Number(totalQuantity);
  }
  calculateTotalPrice(productIndex) {
    return new Promise<void>((resolve, reject) => {
      try {
        const productsArray = this.productForm.get('products') as FormArray;
        const productFormGroup = productsArray.at(productIndex) as FormGroup;
        const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
        const nanArray = productFormGroup.get('nan') as FormArray;

        let totalPrice = 0
        let totalPerzanalizationPrice = 0

        for (let i = 0; i < sizesArray.value.length; i++) {
          let totalSizeQuantityInNAN = nanArray.value.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));

          if (!sizesArray.value[i].size || !sizesArray.value[i].quantity && !totalSizeQuantityInNAN?.length)
            continue
          let basePrice = this.getPriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)

          let basePlusDeco = 0
          if (productFormGroup.value.isDiscountApproved) {
            let upCharge = this.getUpChargePriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)
            basePlusDeco = productFormGroup.value.discountApprovedAmount + upCharge
          } else {
            basePlusDeco = basePrice + productFormGroup.value.decoPrice
          }

          let sizeQuantity = sizesArray.value[i].quantity
          // if (!totalSizeQuantityInNAN?.length) {
          //   totalSizeQuantityInNAN = this.bulkOrer?.cartItemsInfo[productIndex]?.nan?.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
          // }
          let quantity = totalSizeQuantityInNAN?.length
          let personalizationPrice = 0
          let nameCount = 0
          let numberCount = 0

          if (totalSizeQuantityInNAN?.length) {

            let personalizationNameCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "")?.length
            let personalizationNumberCount = totalSizeQuantityInNAN.filter(obj => obj.number != null && obj.number != "")?.length

            let personalizationNameOrNumberCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "" || obj.number != null && obj.number != "")?.length
            if (productFormGroup.value.personalizationProperties) {
              let pTypes = productFormGroup.value.personalizationProperties.map(item => item.personalizationTypeId);
              nameCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 1).length;
              numberCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 2).length;
            }
            let nameDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 1);
            let numberDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 2);
            if (personalizationNameCount) {
              sizesArray['controls'][i].get('personalizationNamePrice').setValue(personalizationNameCount * Number(nameDecoPrice[0]?.personalizationCost || 0))
              if (nameCount == 0) {
                nameCount = 1
              }
              personalizationPrice += (personalizationNameCount * (Number(nameDecoPrice[0]?.personalizationCost || 0) * nameCount))
            }
            if (personalizationNumberCount) {
              sizesArray['controls'][i].get('personalizationNumberPrice').setValue(personalizationNumberCount * Number(numberDecoPrice[0]?.personalizationCost || 0))
              if (numberCount == 0) {
                numberCount = 1
              }
              personalizationPrice += (personalizationNumberCount * (Number(numberDecoPrice[0]?.personalizationCost || 0) * numberCount))

            }
            if (!personalizationNameCount) {
              sizesArray['controls'][i].get('personalizationNamePrice').setValue(0)
            }
            if (!personalizationNumberCount) {
              sizesArray['controls'][i].get('personalizationNumberPrice').setValue(0)
            }
            sizesArray['controls'][i].get('decoPrice').setValue(sizeQuantity * productFormGroup.value.decoPrice)

            sizesArray['controls'][i].get('totalPrice').setValue((((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : sizesArray.value[i].sellingPrice)) * (sizesArray.value[i].quantity)) + personalizationPrice)

            let quantitY = personalizationNameOrNumberCount ? personalizationNameOrNumberCount : sizeQuantity
            let prodPrice = (quantitY * (basePlusDeco))
            totalPrice += ((prodPrice) + Number(personalizationPrice))

            if (personalizationNameOrNumberCount > 0 && sizeQuantity > personalizationNameOrNumberCount) {
              let balanceQuantity = sizeQuantity - personalizationNameOrNumberCount
              let balanceProdPrice = (balanceQuantity * (basePlusDeco))
              totalPrice += balanceProdPrice
            }
          }
          else {
            quantity = sizesArray.value[i].quantity
            sizesArray['controls'][i].get('personalizationNamePrice').setValue(0)
            sizesArray['controls'][i].get('personalizationNumberPrice').setValue(0)
            sizesArray['controls'][i].get('decoPrice').setValue(sizeQuantity * productFormGroup.value.decoPrice)
            totalPerzanalizationPrice += Number(personalizationPrice)

            totalPrice += ((quantity * (basePlusDeco)) + Number(personalizationPrice))
          }

        }
        let totalPerzPrice = Number(totalPerzanalizationPrice).toFixed(2)
        productFormGroup.get('totalPrice').setValue(totalPrice);
        productFormGroup.get('personalizationPrice').setValue(Number(totalPerzPrice));

        if (totalPerzanalizationPrice == 0) {
          let prodPersprice = 0
          productFormGroup.value.personalizationProperties.forEach(element => {
            prodPersprice += element.personalizationCost
          });
          productFormGroup.get('personalizationPrice').setValue(Number(prodPersprice));
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async saveNAN(productIndex, isClose?) {
    this.commonService.toggleLoading(true)
    let res = this.isValidNANForm(productIndex, isClose)
    if (res?.status) {
      const nansArray = this.nanForm.get('nans') as FormArray;

      const productsArray = this.productForm.get('products') as FormArray;
      const productFormGroup = productsArray.at(productIndex) as FormGroup;
      const nanArray = productFormGroup.get('nan') as FormArray;

      if (nansArray.value?.length) {
        const updatedArray = [];
        nansArray.value.forEach((element, index) => {
          element.sortOrder = index
          if (element.id != 0) {
            const index = nanArray?.value?.findIndex(x => x.id == element.id);
            if (index > -1) {
              const singleNan = nanArray.at(index) as FormGroup;
              singleNan.patchValue(element);
              updatedArray.push(singleNan);
            } else {
              const newFormGroup = this.createNANFormGroup(element);
              updatedArray.push(newFormGroup);
            }
          }
        });
        nanArray.clear();
        updatedArray.forEach(item => nanArray.push(item));
        nansArray.value.forEach(element => {
          if (element.id == 0) {
            nanArray.push(this.createNANFormGroup(element));
          }
        });
        productFormGroup.patchValue({ totalQuantity: this.getTotalQuantity(productIndex) })
      }

      if (!isClose) {
        try {
          let totalDiscount: Number = 0
          await this.getDecoPrice(productIndex)
          await this.calculateTotalPrice(productIndex)
          totalDiscount = await this.getTotalDiscount()

          let allNanArray = []
          productsArray.value.forEach(element => {
            if (element.nan?.length) {

              if (productFormGroup.value.cartId == element.cartId) {
                element.nan.forEach(x => {
                  x.totalPrice = element.totalPrice,
                    x.personalizationPrice = element.personalizationPrice,
                    x.decoPrice = element.decoPrice,
                    x.totalDiscount = totalDiscount
                });
              }

              allNanArray.push(...element.nan)
            }
          });
          await this.storeService.saveNAN(allNanArray, this.bulkOrer.bulkOrderShoppingCartMasterId).subscribe(res => {
            $('#nanModal').modal('hide');
            this.syncWithOldData(res)
            this.commonService.toggleLoading(false)
            this.commonService.openSuccessSnackBar('Name & Number saved as successfully', "")
            // if (this.bulkOrderId) {  
            //   this.productForm = this.formBuilder.group({
            //     products: this.formBuilder.array([])
            //   });
            //   this.loadBulkOrderDetails(this.bulkOrderId)
            // //   .then(() => {
            // //     this.deleteProductsAfterReload();
            // // })
            // // .catch((error) => {
            // //     console.error('Error loading bulk order details:', error);
            // // });

            // } 

          }, (error) => {
            this.commonService.toggleLoading(false)
          })
        } catch (error) {
          this.commonService.toggleLoading(false);
          this.commonService.openErrorSnackBar('Error saving Name & Number', "");
        }

      }



    } else {
      this.commonService.openErrorSnackBar(res?.error, "")
      this.commonService.toggleLoading(false);
    }
  }

  getTotalDiscount(): Promise<Number> {
    return new Promise<Number>((resolve, reject) => {
      try {
        const productsArray = this.productForm.get('products') as FormArray;
        let totalDiscount = 0;
        productsArray.value.forEach((element, index) => {
          totalDiscount += this.getDiscount(index);
        });
        resolve(Number(totalDiscount.toFixed(2)));
      } catch (error) {
        reject(error);
      }
    });
  }
  getTotalDiscountAmount() {
    const productsArray = this.productForm.get('products') as FormArray;
    let totalDiscount = 0
    productsArray.value.forEach((element, index) => {
      totalDiscount += this.getDiscount(index)
    });
    return totalDiscount.toFixed(2);
  }

  getDiscount(i: number): number {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(i) as FormGroup;
    if (productFormGroup?.value?.isDiscountApproved) {
      let oldProductsTotal = 0;
      let newProductsTotal = 0;

      oldProductsTotal = this.calculateTotalPriceBeforeOrAfterDiscount(i, 'before', 0);

      let basePlusDecoPrice = ((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : productFormGroup?.value?.cartItemGrids[0]?.sellingPrice) + productFormGroup?.value?.decoPrice)
      let prodctDiscount = productFormGroup?.value.discountType === 0 ? productFormGroup?.value.discount : (basePlusDecoPrice * productFormGroup?.value.discount / 100);
      const discountApprovedAmount = ((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : productFormGroup?.value?.cartItemGrids[0]?.sellingPrice) + productFormGroup?.value?.decoPrice) - prodctDiscount;

      newProductsTotal = this.calculateTotalPriceBeforeOrAfterDiscount(i, 'after', discountApprovedAmount);

      productFormGroup.get('totalPrice').setValue(newProductsTotal);

      return parseFloat((oldProductsTotal - newProductsTotal).toFixed(2));
    } else {
      return 0.00;
    }
  }
  calculateTotalPriceBeforeOrAfterDiscount(productIndex, beforeOrAfter, discountApprovedAmount) {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(productIndex) as FormGroup;
    const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
    const nanArray = productFormGroup.get('nan') as FormArray;

    let totalPrice = 0
    let totalPerzanalizationPrice = 0

    for (let i = 0; i < sizesArray.value.length; i++) {
      if (!sizesArray.value[i].size || !sizesArray.value[i].quantity)
        continue
      let basePrice = this.getPriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)

      let basePlusDeco = 0
      if (beforeOrAfter == 'after') {
        let upCharge = this.getUpChargePriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)
        basePlusDeco = discountApprovedAmount + upCharge
      } else {
        basePlusDeco = basePrice + productFormGroup.value.decoPrice
      }

      let sizeQuantity = sizesArray.value[i].quantity
      let totalSizeQuantityInNAN = nanArray.value.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
      if (!totalSizeQuantityInNAN?.length) {
        totalSizeQuantityInNAN = this.bulkOrer?.cartItemsInfo[productIndex]?.nan?.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
      }
      let quantity = totalSizeQuantityInNAN?.length
      let personalizationPrice = 0
      let nameCount = 0
      let numberCount = 0

      if (totalSizeQuantityInNAN?.length) {

        let personalizationNameCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "")?.length
        let personalizationNumberCount = totalSizeQuantityInNAN.filter(obj => obj.number != null && obj.number != "")?.length

        let personalizationNameOrNumberCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "" || obj.number != null && obj.number != "")?.length
        if (productFormGroup.value.personalizationProperties) {
          let pTypes = productFormGroup.value.personalizationProperties.map(item => item.personalizationTypeId);
          nameCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 1).length;
          numberCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 2).length;
        }
        let nameDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 1);
        let numberDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 2);
        if (personalizationNameCount) {
          if (nameCount == 0) {
            nameCount = 1
          }
          personalizationPrice += (personalizationNameCount * (Number(nameDecoPrice[0]?.personalizationCost || 0) * nameCount))
        }
        if (personalizationNumberCount) {
          if (numberCount == 0) {
            numberCount = 1
          }
          personalizationPrice += (personalizationNumberCount * (Number(numberDecoPrice[0]?.personalizationCost || 0) * numberCount))

        }
        if (!personalizationNameCount) {
        }
        if (!personalizationNumberCount) {
        }

        totalPerzanalizationPrice += Number(personalizationPrice)

        let quantitY = personalizationNameOrNumberCount ? personalizationNameOrNumberCount : sizeQuantity
        let prodPrice = (quantitY * (basePlusDeco))
        totalPrice += ((prodPrice) + Number(personalizationPrice))

        if (personalizationNameOrNumberCount > 0 && sizeQuantity > personalizationNameOrNumberCount) {
          let balanceQuantity = sizeQuantity - personalizationNameOrNumberCount
          let balanceProdPrice = (balanceQuantity * (basePlusDeco))
          totalPrice += balanceProdPrice
        }
      }
      else {
        quantity = sizesArray.value[i].quantity

        totalPerzanalizationPrice += Number(personalizationPrice)

        totalPrice += ((quantity * (basePlusDeco)) + Number(personalizationPrice))
      }

    }
    let totalPerzPrice = Number(totalPerzanalizationPrice).toFixed(2)

    if (totalPerzanalizationPrice == 0) {
      let prodPersprice = 0
      productFormGroup.value.personalizationProperties.forEach(element => {
        prodPersprice += element.personalizationCost
      });
    }
    return totalPrice
  }

  isValidNANForm(i, isClose?) {
    let status = {
      error: "",
      status: true
    }
    let filteredTextBoxes = this.selectedCustomFields.filter(x => x.customFieldType == 0)
    let filteredDropDowns = this.selectedCustomFields.filter(x => x.customFieldType == 1)

    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(this.modalIndex) as FormGroup;
    //const nanArray = productFormGroup.get('nan') as FormArray;
    const nansArray = this.nanForm.get('nans') as FormArray;
    nansArray.value.forEach(tab => {
      if (this.selectedCustomFields?.length) {
        if (filteredDropDowns?.length > 0) {
          filteredDropDowns.forEach((x, index) => {
            if (x.isMandatory) {
              if (tab?.teams && !tab.teams[index]?.teamId) {
                status = {
                  error: "Please select team",
                  status: false
                }
              }
            }
          })

        }

        if (filteredTextBoxes?.length > 0) {
          filteredTextBoxes.forEach((x, index) => {
            if (x.isMandatory) {
              if (tab?.players && tab.player[index]?.player == "") {
                status = {
                  error: "Please fill player name",
                  status: false
                }
              }
            }
          })

        }
      }


      if (tab.sizeId == "") {
        status = {
          error: "Please select size",
          status: false
        }
      }

      // if (tab.isNameDisabled) {
      //   if (tab.name == "") {
      //     status = {
      //       error: "Please fill name",
      //       status: false
      //     }
      //   }
      // }

      // if (tab.isNumberDisabled) {
      //   if (tab.number == "") {
      //     status = {
      //       error: "Please fill number",
      //       status: false
      //     }
      //   }
      // }


    })
    if (isClose && !status.status) {
      status = {
        error: "Please fill mandatory fields to close this window",
        status: false
      }
    }
    return status
  }
  getTeams() {
    let teams = []
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(this.modalIndex) as FormGroup;
    if (this.selectedCustomFields?.length > 0) {
      let filtered = this.tempSelectedCustomFields.filter(x => x.customFieldType == 1)
      filtered?.forEach((x, index) => {
        const filteredTeams = this.filterTeamsByCartId(x.multipleChoices, productFormGroup?.value?.cartId);
        if (filteredTeams?.length) {
          // Create a new object to avoid modifying the original array
          const modifiedX = { ...x, multipleChoices: filteredTeams };
          teams.push(modifiedX); // Push the modified object to teams array
        } else {
          teams.push(x); // If no changes needed, push the original object
        }
      })
    }
    return teams
  }

  filterTeamsByCartId(teams, cartId): Team[] {
    return teams?.filter(team => {
      const cartIdsArray = team?.cartIds ? team.cartIds.split(',').map(id => id.trim()) : [];
      return cartIdsArray?.includes(cartId?.toString());
    })
    // .map(team => ({
    //   ...team,
    //   cartIds: [cartId] // return only the matching cartId in the cartIds array
    // }));
  }
  getPlayerValidations() {
    let validations = []
    if (this.selectedCustomFields?.length > 0) {
      let filtered = this.selectedCustomFields.filter(x => x.customFieldType == 0)
      validations = filtered
    }
    return validations;
  }
  getSizesForNAN(i) {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(i) as FormGroup;
    return productFormGroup?.value?.cartItemGrids ? productFormGroup.value.cartItemGrids : []
    //const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
    // let filteredCartItemGrids = productFormGroup?.value?.cartItemGrids.filter(item => Number(item.quantity) !== 0);  
    // if(filteredCartItemGrids){
    //   return filteredCartItemGrids
    // }else {
    //   return []
    // }

  }
  addRow() {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(this.modalIndex) as FormGroup;
    const nanArray = productFormGroup.get('nan') as FormArray;
    const nansArray = this.nanForm.get('nans') as FormArray;

    const mappedData = productFormGroup?.value?.personalizationProperties?.map(item => {
      let showName = false;
      let showNumber = false;
      if (Number(item.personalizationTypeId) === 1) {
        showName = true;
      } else if (Number(item.personalizationTypeId) === 2) {
        showNumber = true;
      }
      return {
        showName,
        showNumber
      };
    });
    const hasShowName = mappedData.some(item => item.showName === true);
    const hasShowNumber = mappedData.some(item => item.showNumber === true);


    //let filteredCartItemGrids = productFormGroup?.value?.cartItemGrids.filter(item => Number(item.quantity) !== 0);  
    // let possibleCount:Number=0
    // filteredCartItemGrids.forEach(element => {
    //   if(element.quantity!=null ) {
    //     possibleCount+=element.quantity
    //   }
    // });

    //if(nansArray?.value?.length<possibleCount){
    let obj = {
      id: '' || 0,
      cartId: productFormGroup?.value?.cartId,
      team: '',
      teamId: null,
      player: '',
      playerId: '' || 0,
      productName: productFormGroup.value.productDisplayName,
      productId: productFormGroup.value.productId,
      color: productFormGroup.value.selectedColor.name,
      colorId: productFormGroup.value.selectedColor.id,
      size: '',
      sizeId: '',
      name: '',
      number: '',
      isNameDisabled: hasShowName,
      isNumberDisabled: hasShowNumber,
      players: undefined,
      teamIds: undefined,
    }
    //nanArray.push(this.createNANFormGroup(obj));
    nansArray.push(this.createNANFormGroup(obj));
    // }else{
    //   this.commonService.openErrorSnackBar("Size quantity exceeds !","")
    // }

  }
  removeRow(index: number, sizeToFind) {
    const nansArray = this.nanForm.get('nans') as FormArray;
    if (nansArray.value?.length > 1) {
      nansArray.removeAt(index);
    }
  }
  isDisabled2(inputSize: number): boolean {
    const nansArray = this.nanForm.get('nans') as FormArray;
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(this.modalIndex) as FormGroup;
    const cartItemArray = productFormGroup.get('cartItemGrids') as FormArray;
    let res = false
    if (nansArray.value?.length) {
      let singleSizeCount = cartItemArray.value.find(obj => Number(obj.sizeId) === Number(inputSize));
      let singleSizeNANarrayCount = nansArray.value.filter(obj => Number(obj.sizeId) === Number(inputSize));
      if (singleSizeCount?.quantity > singleSizeNANarrayCount?.length) {
        res = false
      } else {
        res = true
      }
    }
    return res
  }
  // updateTeamId(nanIndex: number, teamIndex: number, value: string) {
  //   const nans = this.nanForm.get('nans') as FormArray;   

  //   const teamsArray = nans.at(nanIndex).get('teams') as FormArray;
  //   if (teamsArray.length > teamIndex) {
  //     teamsArray.at(teamIndex).setValue(value);
  //   } else {
  //     while (teamsArray.length <= teamIndex) {
  //       teamsArray.push(this.formBuilder.control(''));
  //     }
  //     teamsArray.at(teamIndex).setValue(value);
  //   }
  //   const nan = (nans.controls[nanIndex] as FormGroup) .get("teamId")   
  //   nan.patchValue( teamsArray?.value.join(', ') );
  // }

  // updatePlayer(nanIndex: number, playerIndex: number, value: string) {
  //   const nans = this.nanForm.get('nans') as FormArray;   

  //   const playersArray = nans.at(nanIndex).get('players') as FormArray;
  //   if (playersArray.length > playerIndex) {
  //     playersArray.at(playerIndex).setValue(value);
  //   } else {
  //     while (playersArray.length <= playerIndex) {
  //       playersArray.push(this.formBuilder.control(''));
  //     }
  //     playersArray.at(playerIndex).setValue(value);
  //   }
  //   const nan = (nans.controls[nanIndex] as FormGroup) .get("player")   
  //   nan.patchValue( playersArray?.value.join(', ') );
  // }
  getMultipleChoices(t) {
    return this.getTeams()[t]?.multipleChoices
  }
  updateTeamId(i: number, t: number, value: any) {
    const control = (this.nanForm.get('nans') as FormArray).at(i) as FormGroup;
    const teams = control.get('teams') as FormArray;
    teams.at(t).setValue({ teamId: value });
    const teamIds = control.value.teams.map(teamControl => teamControl.teamId).join(',');
    control.get('teamIds').setValue(teamIds);
  }

  updatePlayer(i: number, p: number, value: any) {
    const control = (this.nanForm.get('nans') as FormArray).at(i) as FormGroup;
    const players = control.get('players') as FormArray;
    players.at(p).setValue({ player: value });
    const playersList = control.value.players.map(playerControl => playerControl.player).join(',');
    control.get('player').setValue(playersList);
  }
  // getTotalDiscount(){
  //   const productsArray = this.productForm.get('products') as FormArray;
  //   let totalDiscount=0
  //   productsArray.value.forEach((element,index) => {
  //     totalDiscount +=this.getDiscount(index)
  //   });
  //   return totalDiscount.toFixed(2);
  // }
  // getDiscount(i: number): number {
  //   const productsArray = this.productForm.get('products') as FormArray;
  //   const productFormGroup = productsArray.at(i) as FormGroup;
  //   if (productFormGroup?.value?.isDiscountApproved) {
  //     let oldProductsTotal = 0;
  //     let newProductsTotal = 0;

  //     oldProductsTotal = this.calculateTotalPriceBeforeOrAfterDiscount(i, 'before', 0);

  //     let basePlusDecoPrice=(productFormGroup?.value?.cartItemGrids[0]?.sellingPrice  + productFormGroup?.value?.decoPrice )
  //     let prodctDiscount = productFormGroup?.value.discountType === 0 ? productFormGroup?.value.discount :  (basePlusDecoPrice  * productFormGroup?.value.discount / 100);
  //     const discountApprovedAmount = (productFormGroup?.value?.cartItemGrids[0]?.sellingPrice + productFormGroup?.value?.decoPrice) - prodctDiscount;

  //     newProductsTotal = this.calculateTotalPriceBeforeOrAfterDiscount(i, 'after', discountApprovedAmount);

  //     return parseFloat((oldProductsTotal - newProductsTotal).toFixed(2));
  //   } else {
  //     return 0.00;
  //   }
  // }
  // calculateTotalPriceBeforeOrAfterDiscount(productIndex,beforeOrAfter,discountApprovedAmount) {
  //   const productsArray = this.productForm.get('products') as FormArray;
  //   const productFormGroup = productsArray.at(productIndex) as FormGroup;
  //   const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
  //   const nanArray = productFormGroup.get('nan') as FormArray;

  //   let totalPrice = 0
  //   let totalPerzanalizationPrice = 0

  //   for (let i = 0; i < sizesArray.value.length; i++) {
  //     if (!sizesArray.value[i].size || !sizesArray.value[i].quantity)
  //       continue
  //     let basePrice = this.getPriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)

  //       let basePlusDeco=0
  //       if(beforeOrAfter=='after'){
  //         let upCharge= this.getUpChargePriceBySizeName(sizesArray.value[i].sizeId, productFormGroup.value)
  //         basePlusDeco=discountApprovedAmount+upCharge
  //       }else{
  //         basePlusDeco=basePrice+productFormGroup.value.decoPrice
  //       }

  //     let sizeQuantity = sizesArray.value[i].quantity
  //     let totalSizeQuantityInNAN = nanArray.value.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
  //     if (!totalSizeQuantityInNAN?.length) {
  //       totalSizeQuantityInNAN = this.bulkOrer?.cartItemsInfo[productIndex]?.nan?.filter(obj => Number(obj.sizeId) === Number(sizesArray.value[i].sizeId));
  //     }
  //     let quantity = totalSizeQuantityInNAN?.length
  //     let personalizationPrice = 0
  //     let nameCount = 0
  //     let numberCount = 0

  //     if (totalSizeQuantityInNAN?.length) {

  //       let personalizationNameCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "")?.length
  //       let personalizationNumberCount = totalSizeQuantityInNAN.filter(obj => obj.number != null && obj.number != "")?.length

  //       let personalizationNameOrNumberCount = totalSizeQuantityInNAN.filter(obj => obj.name != null && obj.name != "" || obj.number != null && obj.number != "")?.length
  //       if (productFormGroup.value.personalizationProperties) {
  //         let pTypes = productFormGroup.value.personalizationProperties.map(item => item.personalizationTypeId);
  //         nameCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 1).length;
  //         numberCount = pTypes.filter(personalizationTypeId => personalizationTypeId == 2).length;
  //       }
  //       let nameDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 1);
  //       let numberDecoPrice = productFormGroup.value.personalizationProperties.filter(item => item.personalizationTypeId == 2);
  //       if (personalizationNameCount) {
  //         if (nameCount == 0) {
  //           nameCount = 1
  //         }
  //         personalizationPrice += (personalizationNameCount * (Number(nameDecoPrice[0]?.personalizationCost || 0) * nameCount))
  //       }
  //       if (personalizationNumberCount) {
  //         if (numberCount == 0) {
  //           numberCount = 1
  //         }
  //         personalizationPrice += (personalizationNumberCount * (Number(numberDecoPrice[0]?.personalizationCost || 0) * numberCount))

  //       }
  //       if (!personalizationNameCount) {
  //       }
  //       if (!personalizationNumberCount) {
  //       }

  //       totalPerzanalizationPrice += Number(personalizationPrice)

  //       let quantitY = personalizationNameOrNumberCount ? personalizationNameOrNumberCount : sizeQuantity
  //       let prodPrice = (quantitY * (basePlusDeco))
  //       totalPrice += ((prodPrice) + Number(personalizationPrice))

  //       if(personalizationNameOrNumberCount > 0 && sizeQuantity > personalizationNameOrNumberCount){
  //         let balanceQuantity=sizeQuantity-personalizationNameOrNumberCount
  //         let balanceProdPrice = (balanceQuantity * (basePlusDeco))
  //         totalPrice +=balanceProdPrice
  //       }
  //     }
  //     else {
  //       quantity = sizesArray.value[i].quantity

  //       totalPerzanalizationPrice += Number(personalizationPrice)

  //       totalPrice += ((quantity * (basePlusDeco)) + Number(personalizationPrice))
  //     }

  //   }
  //   let totalPerzPrice = Number(totalPerzanalizationPrice).toFixed(2) 

  //   if(totalPerzanalizationPrice==0){
  //     let prodPersprice=0
  //     productFormGroup.value.personalizationProperties.forEach(element => {
  //        prodPersprice += element.personalizationCost
  //     }); 
  //   }
  //   return totalPrice
  // }
  // getPriceBySizeName(sizeId,product) {
  //   if (sizeId && product) {
  //     let sizeObj = product.productInfo.variants[0].sizeVariants.find(
  //       (size) => size.sizeId == sizeId
  //     );
  //       return sizeObj ? sizeObj.sellingPrice : 0
  //   } else {
  //     return 0;
  //   }
  // }
  // getUpChargePriceBySizeName(sizeId, product) {
  //   if (!sizeId || !product?.cartItemGrids?.length) {
  //     return 0;
  //   }
  //   const singleSizeById = product.cartItemGrids.find(x => x.sizeId === sizeId);
  //   return singleSizeById ? singleSizeById.sellingPrice - product.cartItemGrids[0].sellingPrice : 0;
  // }
  getSubTotal() {
    const productsArray = this.productForm.get('products') as FormArray;
    let sum = 0
    productsArray.value.forEach(element => {
      sum += element.totalPrice
    });
    this.checkoutObj.totalPrice = Number(sum)
    return Number(sum).toFixed(2)
  }

  getTotalPrice(productIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    let totalPrice = 0
    if (productsArray?.value[productIndex]?.totalPrice) {
      totalPrice = productsArray?.value[productIndex]?.totalPrice
    }
    return totalPrice?.toFixed(2);
  }
  async syncWithOldData(response) {

    const productsArray = this.productForm.get('products') as FormArray;

    if (response.data && response.data && productsArray.value?.length == response?.data?.cartItemsInfo?.length) {
      this.bulkOrer = response.data;
      this.totalWeight = response?.data?.totalWeight
      this.shippingBoxes = response?.data?.shippingBoxes
      let user = response.data.user;
      this.selectedCustomFields = response?.data?.customFields
      this.tempSelectedCustomFields = JSON.parse(JSON.stringify(this.selectedCustomFields));
      this.isPaymentCompleted = response.data?.cartItemsInfo[0]?.cartType === 1;
      if (this.isPaymentCompleted) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Order already placed !',
          confirmButtonText: 'OK'
        }).then(() => {

        })
      }
      this.isRejectedCart = response.data?.cartItemStatus === 2;
      if (this.isRejectedCart) {
        Swal.fire({
          icon: 'success',
          title: 'Rejected',
          text: 'Order already rejected !',
          confirmButtonText: 'OK'
        }).then(() => {

        })
      }
      for (let i = 0; i < response?.data?.cartItemsInfo?.length; i++) {
        try {
          const productFormGroup = productsArray.at(i) as FormGroup;
          if (!productFormGroup.value.cartId) {
            productFormGroup.patchValue({ cartId: response?.data?.cartItemsInfo[i].cartId, quantity: response?.data?.cartItemsInfo[i].quantity, totalPrice: response?.data?.cartItemsInfo[i].totalPrice })
          }

          const sizesArray = productFormGroup.get('cartItemGrids') as FormArray;
          let sizeVariants = response?.data?.cartItemsInfo[i]?.cartItemGrids;
          sizeVariants.forEach((sizeVariant, index) => {
            const sizeFormGroup = sizesArray.at(index) as FormGroup;
            if (sizeFormGroup.value.sizeId == sizeVariant.sizeId) {
              sizeFormGroup.patchValue({ quantity: sizeVariant.quantity })
            }
          });

          let nans = response?.data?.cartItemsInfo[i]?.nan
          const nanArray = productFormGroup.get('nan') as FormArray;
          nanArray.clear();
          nans?.forEach((element, index) => {
            nanArray.push(this.createNANFormGroup2({ ...element, isNameDisabled: false, isNumberDisabled: false }));
          });
          if (response?.data?.cartItemsInfo?.length - 1 == i) {
            this.isAllProductsLoaded = true
            //this.commonService.toggleLoading(false)
          }
          await this.calculateTotalPrice(i)
        } catch (error) {
          console.error('Error in productSelected:', error);
        }
      }
      this.createBillingForm(response?.data?.billingAddress);
      this.createShippingForm(response?.data?.shippingAddress);
      this.getSelectedProdImage()
      this.setProdStatus()

      if (this.isRejectedCart || this.isPaymentCompleted) {
        this.showNanButtons = false
      }
      this.commonService.toggleLoading(false)
    } else {
      this.commonService.toggleLoading(false);
      if (this.bulkOrderId) {
        this.productForm = this.formBuilder.group({
          products: this.formBuilder.array([])
        });
        this.loadBulkOrderDetails(this.bulkOrderId)
        //   .then(() => {
        //     this.deleteProductsAfterReload();
        // })
        // .catch((error) => {
        //     console.error('Error loading bulk order details:', error);
        // });
      }
    }
  }
  getDecoPrice(productIndex, j?) {
    return new Promise<void>((resolve, reject) => {
      const productsArray = this.productForm.get('products') as FormArray;
      const productFormGroup = productsArray.at(productIndex) as FormGroup;
      let totalQuantity = productsArray.value[productIndex].totalQuantity
      let data = []

      let storeData = {
        "storeId": null,
        "mapCode": null,
        "personalizationNameQty": 0,
        "personalizationNumberQty": 0,
        "totalQty": productFormGroup?.value?.totalQuantity
      }

      if (productsArray.value[productIndex].purchaseType) {

        if (productsArray.value[productIndex].purchaseType == 1 || productsArray.value[productIndex].purchaseType == 2) {
          let decoPropertyArr = productsArray.value[productIndex].decorationProperties

          decoPropertyArr.forEach(el => {
            // if (el.serviceName == 'Embroidery') {
            //   el.colorCount = 0
            // }
            //  let ids=el.colorId.map(color => color?.id)
            data.push(this.createDecoPriceApiObj(
              Number(totalQuantity),
              Number(el.colorCount),
              3, // itemType - color
              Number(el.serviceId),
              el.colorId
            ))
          })
        }



        if (this.isValidPersonalizedOptionsInProuct(productIndex)) {
          let personalizationPropertyArr = productFormGroup?.value?.personalizationProperties
          personalizationPropertyArr.forEach((el, index) => {
            if (index == j) {
              if (el.personalizationTypeId == 1 || el.personalizationTypeId == 3)
                storeData.personalizationNameQty += productFormGroup?.value?.totalQuantity
              if (el.personalizationTypeId == 2 || el.personalizationTypeId == 3)
                storeData.personalizationNumberQty += productFormGroup?.value?.totalQuantity
              let count = el.personalizationTypeId == 0 ? 0 :
                el.personalizationTypeId == 1 ?
                  productFormGroup?.value?.totalQuantity :
                  productFormGroup?.value?.totalQuantity // if type = 3 both qty are same
              if (count) {
                let sizeid
                if (el.serviceId == 1) {
                  sizeid = 0
                } else {
                  sizeid = el.sizeId
                }
                data.push(this.createDecoPriceApiObj(
                  Number(count),
                  Number(sizeid),
                  Number(el.personalizationTypeId), // itemType - 1,2
                  Number(el.serviceId),
                  [Number(el.colorId)]
                ))
              }
            }
          });
        }

      }

      if (productsArray.value[productIndex].purchaseType != 2 && !this.isValidPersonalizedOptionsInProuct(productIndex)) {

        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
      } else if (productsArray.value[productIndex].purchaseType == 2 && this.checkDecoPropertiesIsValidOrNotForProduct(productIndex) == false) {
        //this.commonService.openErrorSnackBar("Please complete decoration settings to calculate total price", "")
        return
      } else if (this.isValidPersonalizedOptionsInProuct(productIndex) &&
        ((storeData.personalizationNameQty && !this.isValidPersonalizedOptionsInProuct(productIndex)) ||
          (storeData.personalizationNumberQty && !this.isValidPersonalizedOptionsInProuct(productIndex)))
      ) {
        this.commonService.openErrorSnackBar("Please complete personalization settings to calculate total price", "")
        return
      } else if (!totalQuantity) {
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        // return
      } else if (!data.length) {
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        // return
      } else {
        this.decoPrice = {
          decoPrice: 0,
          namePrice: 0,
          numberPrice: 0,
        }
        // this.decoPriceLoading = true
        this.commonService.toggleLoading(true)
        this.sharedServices.getDecoPriceByProperties(data).subscribe(res => {
          // this.decoPriceLoading = false
          // this.commonService.toggleLoading(false)
          if (res?.data?.decoPrice > -1) {
            productFormGroup.get('decoPrice').setValue(res.data.decoPrice);
            //this.getProuctDecoPrice(productIndex);
            let unitPrice = ((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : productFormGroup?.value?.productInfo?.variants[0].sizeVariants[0].sellingPrice) + res.data.decoPrice).toFixed(2)
            let TotalPrice = (unitPrice * totalQuantity)
            productFormGroup.get('perUnit').setValue(totalQuantity > 0 ? unitPrice : 0);
            productFormGroup.get('totalPrice').setValue(TotalPrice);

            const sizes = productFormGroup.get('cartItemGrids') as FormArray;
            sizes?.controls.forEach(element => {
              element.get('decoPrice').setValue(res?.data?.decoPrice * element?.value.quantity);
            });


            let personalizations = productFormGroup.get('personalizationProperties') as FormArray;
            let singlePersonalization = personalizations.at(j) as FormGroup;
            let personalizationCost = '';
            if (singlePersonalization?.value?.personalizationTypeId == 1) {
              personalizationCost = res?.data?.namePrice
            } else if (singlePersonalization?.value?.personalizationTypeId == 2) {
              personalizationCost = res?.data?.numberPrice
            }
            personalizationCost = Number(personalizationCost).toFixed(2)
            singlePersonalization?.get('personalizationCost').setValue(Number(personalizationCost));

            let persPrice: number = 0
            personalizations?.value?.forEach(element => {
              persPrice += Number(element?.personalizationCost || 0)
            });
            persPrice = Math.round(persPrice * 100) / 100;
            productFormGroup.get('personalizationPrice').setValue(Number(persPrice));

            // this.calculateTotalPrice(productIndex)

          }
          resolve();
        })
        //this.calculateTotalPrice(productIndex)
      }

    });
  }
  createDecoPriceApiObj(count, itemValue, itemType, serviceId, colorIds) {
    return { count, itemValue, itemType, serviceId, colorIds }
  }
  checkDecoPropertiesIsValidOrNotForProduct(prodIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    const decorationPropertiesArray = (productsArray.at(prodIndex).get('decorationProperties') as FormArray);
    const isDecorationPropertiesValid = decorationPropertiesArray.valid;
    return isDecorationPropertiesValid;
  }
  getPerUnitPrice(productIndex) {
    const productsArray = this.productForm.get('products') as FormArray;
    const productFormGroup = productsArray.at(productIndex) as FormGroup;
    if (productFormGroup?.value?.isDiscountApproved) {
      let basePlusDecoPrice = ((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : productFormGroup?.value?.cartItemGrids[0]?.sellingPrice) + productFormGroup?.value?.decoPrice)
      let prodctDiscount = productFormGroup?.value.discountType === 0 ? productFormGroup?.value.discount : (basePlusDecoPrice * productFormGroup?.value.discount / 100);
      const discountApprovedAmount = ((productFormGroup?.value?.isCustomerProvideGoods == true ? 0 : productFormGroup?.value?.cartItemGrids[0]?.sellingPrice) + productFormGroup?.value?.decoPrice) - prodctDiscount;
      return parseFloat((discountApprovedAmount).toFixed(2));
    } else {
      return productsArray.value[productIndex].perUnit
    }
  }

  onFileSelect(event: any) {
    const files = event.target.files;
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        if (files[i] && allowedTypes.includes(files[i].type)) {
          this.uploadedPO.push(files[i]);
        } else {
          this.commonService.openErrorSnackBar("Invalid files removed !", "")
        }
      }
      this.purchaseOrderForm.patchValue({
        attachment: this.uploadedPO
      });
    }
  }
  removeFile(index: number): void {
    this.uploadedPO.splice(index, 1);
    this.purchaseOrderForm.patchValue({
      attachment: this.uploadedPO.length > 0 ? this.uploadedPO : null
    });
  }
  trimFileName(fileName: string, maxLength: number = 15): string {
    if (fileName.length > maxLength) {
      const extension = fileName.substring(fileName.lastIndexOf('.'));
      return `${fileName.substring(0, maxLength)}...${extension}`;
    }
    return fileName;
  }
  subTotalwithoutDiscountOrCoupon() {
    let oldProductsTotal = 0;
    const productsArray = this.productForm.get('products') as FormArray;
    productsArray?.value?.forEach((element, index) => {
      if (!element.isDiscountApproved) {
        oldProductsTotal += element?.totalPrice;
      } else {
        oldProductsTotal += this.calculateTotalPriceBeforeOrAfterDiscount(index, 'before', 0);
      }
    });
    return parseFloat((oldProductsTotal).toFixed(2));
  }

  getTax() {
    // return new Promise<void>((resolve, reject) => {
    //this.commonService.toggleLoading(true)
    this.isTaxLoading = true;
    let stateCode = "";
    let state = this.states.find(
      (state) => Number(state.name) == this.shippingForm.value.state
    );
    stateCode = state ? state.code : "";
    let quantity = 0
    this.productForm?.value?.products?.forEach((element) => {
      quantity = quantity + element.totalQuantity
    });
    if (
      this.shippingForm.value.state &&
      this.shippingForm.value.zipCode &&
      quantity
    ) {
      // let shpAmount;
      // if (this.shippingAfterCouponApplied !== undefined && this.shippingAfterCouponApplied !== null && this.selectedShippingMethod?.id==this.CouponApplyShippingMethodId) {
      //       shpAmount = Number(this.shippingAfterCouponApplied);
      // } else {
      //       shpAmount = Number(this.selectedShippingMethod?.price || 0);
      // }

      //let amount=this.offerApplied ? Number(this.subtotalPrice)+(shpAmount ) : Number(this.checkoutObj.totalPrice)+(Number(this.selectedShippingMethod?.price||0))

      let amount = Number(this.getSubTotal()) + Number(this.bulkOrer?.shippingAmount);

      this.sharedServices.getTax({
        State: this.shippingForm.value.state,
        ZipCode: this.shippingForm.value.zipCode,
        // "Country": "US",
        Amount: amount,
        Quantity: quantity
      })
        .subscribe((res) => {
          if (this.paymentMethod != 4) {
            //this.commonService.toggleLoading(false)
          }
          this.taxObject = res.data;
          this.taxAmount = res?.data?.totalTax
          if (res.statusCode == 200) {
            this.isTaxLoading = false;
            //this.commonService.toggleLoading(false)
          } else {
            this.isTaxLoading = true;
            if (this.paymentMethod != 4) {
              //this.commonService.toggleLoading(false)
            }
          }
          // resolve()
        },
          (err) => {
            if (this.paymentMethod != 4) {
              //this.commonService.toggleLoading(false)
            }
          });
    }
    // resolve()
    // });
  }
  getStates() {
    this.sharedServices.getAllStates().subscribe(res => {
      this.states = res.data
    })
  }
  getFinalTotalPrice() {
    let subTotal = Number(this.getSubTotal()) || 0;
    let shippingAmount = Number(this.bulkOrer?.shippingAmount) || 0;
    let taxAmount = Number(this.taxAmount) || 0;
    let amount = subTotal + shippingAmount + taxAmount;
    return parseFloat(amount.toFixed(2));
  }


}
